<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <main-view-header />
      <!-- <ion-segment
        swipe-gesture="true"
        :value="String(selectedView)"
        style="width: 300px;"
        @ionChange="selectedView = $event.target.value"
      >
        <ion-segment-button value="1">
          <ion-label class="text-18">
            {{ $t('overview') }}
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="2">
          <ion-label class="text-18">
            {{ $t('cameras') }}
          </ion-label>
        </ion-segment-button>
      </ion-segment> -->
    </ion-header>
    <ion-content>
      <div
        v-if="selectedView === '1'"
        class=""
      >
        <div class="security-overview">
          <div
            class="security-icon ion-padding-start ion-padding-end"
            tappable
            @click="toggleAlarm"
          >
            <div v-if="languageSelected === 'EN'">
              <img
                v-show="!propertyAlarm"
                src="../assets/images/alarm-disarmed.png"
                class="securityicon-svg"
              >
              <img
                v-show="propertyAlarm"
                src="../assets/images/alarm-armed.png"
                class="securityicon-svg"
              >
            </div>
            <div v-else-if="languageSelected === 'SE'">
              <img
                v-show="!propertyAlarm"
                src="../assets/images/alarm-disarmed-se.png"
                class="securityicon-svg"
              >
              <img
                v-show="propertyAlarm"
                src="../assets/images/alarm-armed-se.png"
                class="securityicon-svg"
              >
            </div>
            <div
              v-if="doorWindowDevices.length || motionDevices.length || otherDevices.length"
              class="verified"
            >
              <ion-text
                v-if="sirenMissing"
                class="text--primary"
              >
                !! No siren detected !!
              </ion-text>
              <ion-text
                v-else-if="eventDetected"
                class="text--primary"
              >
                <i class="mdi mdi-alert-decagram text--primary text-18" /> {{ $t('Event detected') }}
              </ion-text>
              <ion-text
                v-else
                class="text--white text-16"
              >
                <i class="mdi mdi-check-decagram text--primary text-18" /> {{ $t('All sensors are cleared') }}
              </ion-text>
            </div>
          </div>
          <div
            v-if="doorWindowDevices.length"
            class="doors-windows"
          >
            <div class="text-left ion-padding-start ion-padding-end">
              <ion-text class="text--white text-16 text-bold">
                {{ $t('DOORS AND WINDOWS') }}
              </ion-text>
            </div>
            <div>
              <keep-alive>
                <swiper
                  id="securitySlides"
                  ref="securitySlides"
                  class="sliderSecurity"
                  :slides-per-view="1.2"
                  :space-between="10"
                >
                  <swiper-slide
                    v-for="item in doorWindowDevices"
                    :key="item.id"
                  >
                    <ion-item
                      class="h-65"
                      lines="none"
                      button
                      detail="false"
                      @click="openDeviceHistoryPopover(item, roomNameMapper(item.propertyRoomId))"
                    >
                      <ion-avatar>
                        <img src="../assets/images/doors.svg">
                      </ion-avatar>
                      <ion-label class="text--white">
                        {{ item.deviceName }} <p>{{ roomNameMapper(item.propertyRoomId) }}</p>
                      </ion-label>
                      <span
                        v-show="item.status === 'Closed'"
                        slot="end"
                        class="material-icons"
                      >lock</span>
                      <span
                        v-show="item.status === 'Open'"
                        slot="end"
                        class="material-icons"
                        style="padding: 3px!important;"
                      ><i
                        class="mdi mdi-lock-open-variant"
                      /></span>
                    </ion-item>
                  </swiper-slide>
                  <swiper-slide />
                </swiper>
              </keep-alive>
            </div>
          </div>
          <div
            v-if="motionDevices.length"
            class="security-devices ion-padding-start ion-padding-end"
          >
            <div class="text-left">
              <ion-text class="text--white text-">
                {{ $t('MOTION DETECTORS') }}
              </ion-text>
            </div>
            <div
              v-for="item in motionDevices"
              :key="item"
            >
              <ion-item
                class="h-100"
                lines="none"
                button
                detail="false"
                @click="openDeviceHistoryPopover(item, roomNameMapper(item.propertyRoomId))"
              >
                <ion-avatar>
                  <img src="../assets/devices/SensorMotion.png">
                </ion-avatar>
                <ion-label class="text--white">
                  <p style="color: white;">
                    {{ item.deviceName }}
                  </p>
                  <p style="color: #D9E1D980;">
                    {{ roomNameMapper(item.propertyRoomId) }}
                  </p>
                </ion-label>
                <span v-show="item.status === 'No Movement'">OK</span>
                <!-- <span v-show="item.status === 'Movement Detected'"><span class="redz">.</span>!!</span> -->
                <i
                  v-show="item.status === 'Movement Detected'"
                  class="mdi mdi-alert text--primary alert-icon"
                />
              </ion-item>
            </div>
          </div>
          <div
            v-if="otherDevices.length"
            class="security-devices ion-padding-start ion-padding-end"
          >
            <div class="text-left">
              <ion-text class="text--white text-">
                {{ $t('OTHER SECURITY DEVICES') }}
              </ion-text>
            </div>
            <div
              v-for="item in otherDevices"
              :key="item"
            >
              <ion-item
                lines="none"
                class="h-100"
                button
                detail="false"
                @click="openDeviceHistoryPopover(item, roomNameMapper(item.propertyRoomId))"
              >
                <ion-avatar>
                  <img :src="require('../assets/devices/' + item.zigbeeModel + '.png')">
                </ion-avatar>
                <ion-label class="text--white">
                  <p style="color: white;">
                    {{ item.deviceName }}
                  </p>
                  <p style="color: #D9E1D980;">
                    {{ roomNameMapper(item.propertyRoomId) }}
                  </p>
                </ion-label>
                <span v-show="item.status === 'No Water Leak Detected' || item.status === 'No Smoke Detected' || item.status === 'No Movement'">OK</span>
                <i
                  v-show="item.status === 'Smoke Detected' || item.status === 'Water Leak Detected!' || item.status === 'Movement Detected'"
                  class="mdi mdi-alert text--primary alert-icon"
                />
              </ion-item>
            </div>
          </div>
          <div v-if="!doorWindowDevices.length && !motionDevices.length && !otherDevices.length">
            <div class="mt-30">
              {{ $t('SecurityNoDevicesToDisplay') }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="selectedView === '2'"
        class="room-details ion-padding"
      >
        <div class="cameras">
          <ion-row>
            <ion-col
              size="12"
            >
              <div class="camera-box">
                Bedroom
                <span class="material-icons">
                  play_circle_filled
                </span>
              </div>
            </ion-col>
            <ion-col
              size="12"
            >
              <div class="camera-box">
                Kitchen
                <span class="material-icons">
                  play_circle_filled
                </span>
              </div>
            </ion-col>
            <ion-col
              size="12"
            >
              <div class="camera-box">
                Living Room
                <span class="material-icons">
                  play_circle_filled
                </span>
              </div>
            </ion-col>
          </ion-row>
        </div>
      </div>
      <!-- <div v-else-if="selectedView === '3'">
        <div v-if="sirenExits">
          <div class="mt-20">
            <img
              style="margin: 0 auto; max-width: 200px;"
              :src="require('../assets/devices/IASWarning.png')"
            >
          </div>
          <ion-item
            color="transparent"
            lines="none"
            class="mt-20 text--white text-16"
          >
            <div style="margin: 0 auto;">
              <i
                class="mdi mdi-check-decagram text-18 text--primary"
                style="padding-right: 6px"
              />Siren is inactive
            </div>
          </ion-item>
          <ion-item
            lines="none"
            color="transparent"
            style="padding-left: 25vw; padding-right: 25vw;"
          >
            <div
              slot="start"
              class="text--white text-16"
            >
              Test Siren?
            </div>
            <div
              slot="end"
              style="margin: 0 auto;"
            >
              <ion-toggle />
            </div>
          </ion-item>
          <div class="ion-padding">
            <div class="text-left text--white text--bold text-16">
              LAST ACTIVITY
            </div>
            <ion-row class="mt-10">
              <ion-col class="text--white text-left">
                Bulglary
              </ion-col>
              <ion-col class="text--white text-left">
                15.06.2021 15:45
              </ion-col>
            </ion-row>
            <ion-row class="mt-10">
              <ion-col class="text--white text-left">
                Test
              </ion-col>
              <ion-col class="text--white text-left">
                15.06.2021 15:45
              </ion-col>
            </ion-row>
          </div>
        </div>
        <div v-else>
          <div class="text--gray">
            No Sirene Found<br><br>
            Please add it first
          </div>
        </div>
      </div> -->
    </ion-content>
  </ion-page>
</template>

<script>
import { addOutline } from 'ionicons/icons'
import SwiperCore, { Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { popoverController } from '@ionic/vue'
import { App } from '@capacitor/app'

import mainViewHeader from '../components/Headers/MainViewHeaderComponent.vue'
import deviceHistoryPopOver from '../components/Popovers/DeviceHistoryPopOver.vue'

SwiperCore.use([Virtual])

export default {
  components: {
    Swiper,
    SwiperSlide,
    mainViewHeader
  },
  data () {
    return {
      addOutline,
      selectedView: '1',
      sirenMissing: false,
      slides: 1.1
    }
  },
  computed: {
    languageSelected () {
      console.log(this.$i18n.locale)
      return this.$i18n.locale
    },
    doorWindowDevices () {
      const devices = this.$store.state.propertyDevices
      const doorWindow = devices.filter(item => item.zigbeeModel === 'SensorDoorWindow')
      return doorWindow
    },
    motionDevices () {
      const devices = this.$store.state.propertyDevices
      const motionSensors = devices.filter(item => item.zigbeeModel === 'SensorMotion')
      return motionSensors
    },
    otherDevices () {
      const devices = this.$store.state.propertyDevices
      const sensors = devices.filter(item => item.zigbeeModel === 'SensorWaterLeak' || item.zigbeeModel === 'SensorSmoke' || item.zigbeeModel === 'SensorVibration')
      return sensors
    },
    eventDetected () {
      const devices = this.$store.state.propertyDevices
      const sensors = devices.filter(item => item.status === 'Water Leak Detected' || item.status === 'Movement Detected')
      return !!sensors.length
    },
    sirenExits () {
      const devices = this.$store.state.propertyDevices
      let result
      devices.forEach(item => {
        if (item.zigbeeModel === 'IASWarning') {
          result = true
        }
      })
      return result
    },
    propertyAlarm () {
      return this.$store.state.propertyAlarm
    }
  },
  watch: {
    $route: function () {
      if (this.$route.name === 'Security' && this.doorWindowDevices.length) {
        this.initSwiper()
      }
    }
  },
  mounted () {
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive && this.doorWindowDevices.length) {
        this.initSwiper()
      }
    })
  },
  created () {
  },
  methods: {
    initSwiper () {
      const swip3 = document.querySelector('.sliderSecurity').swiper
      swip3.update()
      setTimeout(() => {
        swip3.update()
      }, 500)
    },
    roomNameMapper (id) {
      const roomId = Number(id)
      const rooms = this.$store.state.propertyRooms
      const found = rooms.find(element => element.id === roomId)
      return found ? found.roomName : '--'
    },
    async openDeviceHistoryPopover (device, room) {
      const popover = await popoverController
        .create({
          component: deviceHistoryPopOver,
          cssClass: 'popover',
          translucent: false,
          componentProps: {
            device,
            room,
            close: () => popover.dismiss()
          }
        })
      await popover.present()
    },
    toggleAlarm () {
      let alarmSetting = false
      if (this.propertyAlarm) {
        alarmSetting = false
      } else {
        alarmSetting = true
      }
      // if (!this.sirenExits) {
      //   this.sirenMissing = true
      //   setTimeout(() => {
      //     this.sirenMissing = false
      //   }, 3000)
      // }
      this.$store.dispatch('setAlarmStatus', alarmSetting)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/theme/_backgrounds";

.h-65:first-of-type {
  margin-left: 16px;
}
.add-button-postion {
  margin-top: 10px;
}
.security-overview .security-icon{
  text-align:center;
}
.verified ion-text img{
    vertical-align: text-top;
}
ion-avatar {
    border-radius: 0px;
    --border-radius: 0px;
    img{
      width:40px;
    }
}
.doors-windows{
margin-top:30px;}
.doors-windows ion-item{
--background: #3F424A;
    padding:6px 0;
    background:#3F424A;
    margin-top: 10px;
    border-radius:10px;
    border: 1px solid #636363;
margin-top:10px;
width:100%;
}
.security-devices ion-item{
--background: #3F424A;
background: #3F424A;
opacity: 0.7;
padding:5px 0;
border-radius: 10px;
height:65px;
margin-top:10px;
color:#fff;
border: 1px solid #636363;
}
.doors-windows ion-item ion-label{
    margin-left: 10px;
    letter-spacing: 0.8px;
    color: #fff;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;}
.doors-windows ion-item ion-label p{
    margin-top: 5px;
    letter-spacing: 0.8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #D9E1D980;
    line-height: 16px;
}
.doors-windows{
ion-item{
img{
width: 22.5px;
    height: 22.5px;
    vertical-align: top;
    margin-top: 7px;
}
}
}
.doors-windows ion-text{
     letter-spacing: 0.8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}
.doors-windows ion-item  span.material-icons {
 color: #ffd4a4;
    padding: 6px;
    text-align: center;
    width: 43px;
    height: 27px;
    background: rgba(120, 120, 128, 0.32);
    border-radius: 30px;
    font-size: 18px;
}
.security-devices{
margin-top:20px;}
.security-devices ion-text{
letter-spacing: 0.8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}
.security-devices ion-item  span{
color: #fff;
    padding: 6px;
    border-radius: 15px;
    font-size:12px;
    width: 43px;
    height: 26px;
    text-align: center;
    background: rgba(120, 120, 128, 0.32);
}

.alert-icon {
    border-radius: 15px;
    font-size: 22px;
    width: 43px;
    height: 26px;
    text-align: center;
    background: rgba(120, 120, 128, 0.32);
}

.security-devices ion-item  span .greenz{
  background: #0CD0A1 !important;
    background: transparent;
    font-size: 1px;
    width: 1px;
    padding: 2px;
    margin-right: 3px;
    vertical-align:middle;
}

.security-devices ion-item  span .redz{
  background: #d00c0c !important;
    background: transparent;
    font-size: 6px;
    width: 6px;
    padding: 2px;
    margin-right: 3px;
    vertical-align:middle;
}

.security-devices ion-label{
margin-left:10px;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
}
.camera-box{
text-align:center;
background:#ddd;
padding:20px;
position: absolute;
    left: 0;
    right: 0;
    border-radius: 10px;
    bottom: 0;
    top: 0;
    color:#fff;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
164.34deg
, rgba(189, 165, 137, 0.4) 34.13%, rgba(85, 76, 119, 0.332973) 65.17%, rgba(9, 21, 39, 0.4) 89.05%);
}
.cameras ion-col{
color: white;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    height:100px;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    background-size: auto 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    position: relative;
    background-image: url("../assets/images/rooms/dinigroom.jpg");
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    border-radius:10px;
    }
    .camera-box .material-icons{
    display:block;
    font-size:30px;margin-top:10px;
    }
    .securityicon-svg{
     margin-top:-20px;
     margin-bottom:-10px;
     max-width: 200px;
    }
.emptyItem {
  height: 50px;
}
</style>
