<template>
  <ion-header
    class="ion-no-border"
  >
    <ion-toolbar
      color="transparent"
    >
      <ion-title
        class="text-18 text--white"
      >
        {{ $t('History') }}
      </ion-title>
    </ion-toolbar>
    <div class="text-center">
      <span class="text--primary">{{ device.deviceName }} {{ $t('in') }} {{ room }}</span>
    </div>
  </ion-header>
  <ion-content
    :scroll-y="true"
    class="ion-padding"
  >
    <div
      v-if="loading"
      class="text--gray"
    >
      <ion-spinner
        color="light"
        name="crescent"
      /><br><br>
      {{ $t('Loading') }}....
    </div>
    <div v-else>
      <div
        v-if="deviceHistory.length"
        style="max-height: 400px"
        class="text--white text-left"
      >
        <div
          v-for="item in deviceHistory"
          :key="item"
        >
          <div
            v-if="device.zigbeeModel === 'SensorDoorWindow'"
            class="mb-10"
          >
            <div class="text--white text-14">
              <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
              <div class="mt-5">
                {{ device.deviceName }} {{ $t('has been') }} {{ deviceStatusMapper(item.status) }}
              </div>
            </div>
          </div>
          <div
            v-else-if="device.zigbeeModel === 'SensorWaterLeak'"
            class="mb-10"
          >
            <p class="text--white text-14">
              <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
              {{ $t('sensorWaterLeakLeakDetected') }}
            </p>
          </div>
          <div
            v-else-if="device.zigbeeModel === 'SensorMotion'"
            class="mb-10"
          >
            <p class="text--white text-14">
              <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
              {{ $t('sensorMotionMovement') }}
            </p>
          </div>
          <div
            v-else-if="device.zigbeeModel === 'SensorSmoke'"
            class="mb-10"
          >
            <p class="text--white text-14">
              <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
              {{ $t('sensorSmokeSmokeDetected') }}
            </p>
          </div>
          <div
            v-else-if="device.zigbeeModel === 'IASWarning'"
            class="mb-10"
          >
            <p class="text--white text-14">
              <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
              {{ $t('securityBreached') }} {{ $t('in') }} {{ deviceRoom(item.roomId) }}
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text--gray">
          {{ $t('No history recorded') }}<br>
        </p>
      </div>
    </div>
  </ion-content>
  <ion-footer
    no-border
    transparent
    class="footer"
  >
    <ion-button
      fill="clear"
      color="transparent"
      @click="dismissPopover"
    >
      <ion-label color="primary">
        {{ $t('close') }}
      </ion-label>
    </ion-button>
  </ion-footer>
</template>

<script>
import { IonContent } from '@ionic/vue'
import { defineComponent } from 'vue'
import dayjs from 'dayjs'

require('../../locales/sv')
require('../../locales/en')

export default defineComponent({
  name: 'Popover',
  components: { IonContent },
  props: {
    device: { type: Object, default: null },
    room: { type: String, default: null },
    close: { type: Function, default: null }
  },
  data () {
    return {
      selectedView: '1',
      loading: false,
      deviceHistory: []
    }
  },
  computed: {
    propertyRooms () {
      return this.$store.state.propertyRooms
    }
  },
  created () {
    this.getHistory(this.device)
  },
  methods: {
    getHistory (device) {
      this.loading = true
      const ieee = device.ieee
      const type = device.typeI
      this.$store.dispatch('getDeviceHistory', { ieee, type }).then(response => {
        if (this.device.typeI === 'SensorMotion' || this.device.typeI === 'SensorVibration' || this.device.typeI === 'SensorMulti') {
          const data = response.data.filter(item => item.type === 'Movement')
          this.deviceHistory = data
          this.loading = false
        } else {
          this.deviceHistory = response.data
          this.loading = false
        }
      })
    },
    dateFormatter (date) {
      const locale = this.$i18n.locale
      console.log(locale)
      let localeActive = ''
      if (locale === 'EN') {
        localeActive = 'en'
      } else if (locale === 'SE') {
        localeActive = 'sv'
      }

      return dayjs(date).locale(localeActive).format('dddd DD.MM.YYYY HH:mm')
    },
    deviceStatusMapper (status) {
      let statusResult = ''
      if (status === 'Initializing...') {
        statusResult = 'Initializing...'
      } else if (status === 'Open') {
        statusResult = this.$t('sensorDoorWindowOpened')
      } else if (status === 'Closed') {
        statusResult = this.$t('sensorDoorWindowClosed')
      } else if (status === 'No Movement') {
        statusResult = this.$t('sensorMotionNoMovement')
      } else if (status === 'Movement Detected') {
        statusResult = this.$t('sensorMotionMovement')
      } else if (status === 'No Smoke Detected') {
        statusResult = this.$t('sensorSmokeNoSmokeDetected')
      } else if (status === 'Smoke Detected') {
        statusResult = this.$t('sensorSmokeSmokeDetected')
      } else if (status === 'No Water Leak Detected') {
        statusResult = this.$t('sensorWaterLeakNoLeakDetected')
      } else if (status === 'Water Leak Detected!') {
        statusResult = this.$t('sensorWaterLeakLeakDetected')
      } else if (status === 'active') {
        statusResult = this.$t('smartSirenStatusActive')
      } else if (status === 'inactive') {
        statusResult = this.$t('smartSirenStatusSilent')
      }
      return statusResult
    },
    removeDevice (ieee, ep, deviceId) {
      this.$store.dispatch('removeDevice', { ieee, ep, deviceId }).then(this.dismissPopover)
    },
    deviceRoom (roomId) {
      let result = ''
      if (roomId === 0) {
        result = 'Unassigned'
      } else {
        const rooms = this.$store.state.propertyRooms
        const found = rooms.find(element => element.id === roomId)
        result = found ? found.roomName : '--'
      }

      return result
    },
    dismissPopover () {
      this.close()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
.buttonRoom {
  padding-left: 2px;
  padding-right: 2px;
}
.inactive {
    --background: rgba(44, 48, 54, 0.6);
    color: white;
    max-height: 44px;
}
.active {
    --background: #3F424A;
    color: colors(primary, base);
    max-height: 44px;
    border: 1px solid colors(primary, base);
    border-radius: 5px;
}
.light-intensity {
  ion-range {
    --bar-background: linear-gradient(to right, black 0%, white 100%) !important;
  }
}
.color-temperature {
  ion-range {
    --bar-background: linear-gradient(to right, white 0%, lightyellow, yellow, orange 100%) !important;
  }
}
.range.range {
  --bar-background-active: transparent;
  --knob-size: 24px;
  padding-left: 13px;
  padding-right: 13px;
  --bar-border-radius: 10px !important;
  --bar-height: 6px !important;
  padding-top: 0px !important;
}
</style>
